import { Carousel } from 'react-responsive-carousel';
import Container from 'react-bootstrap/Container';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export function Screenshots({ screenshots }) {

  return (
    <div className='screenshots__background'>
      <Container className='screenshots__wrapper'>
        <h2>{screenshots.title}</h2>
        <p>{screenshots.body}</p>
        <Carousel
          showStatus={false}
          className='carousel'
          autoPlay={true}
        >
          {screenshots.images.map((image) => (
            <div>
              <img src={image.url} alt={image.alternativeText} title={image.caption} className='img-fluid' />
            </div>
          ))}
        </Carousel>
      </Container>
    </div>
  );
}


export default Screenshots;
