import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../components/homepage/hero';
import Popular from '../components/homepage/popular';
import Mission from '../components/homepage/mission';
import Features from '../components/homepage/features';
import CallToAction from '../components/shared/call-to-action';
import AllFeatures from '../components/homepage/all-features';
import Sneakpeek from '../components/homepage/sneakpeek';
import Faq from '../components/homepage/faq';
import Integrations from '../components/homepage/integrations';
import SEO from '../components/seo';
import StructuredData from "../schema/rentalsoftware.json"
import ContactForm from '../components/ContactForm';

export function Index({ data }) {
  const {
    basicSeo,
    all_features,
    cta,
    faqs,
    features,
    mission,
    popular,
    hero
  } = data.strapiHomepage

  return (
    <Layout>
      <SEO title={basicSeo.seoTitle} description={basicSeo.seoMetadescription} structuredData={StructuredData} index={true} follow={true}></SEO>
      <Hero title={hero.title} subtitle={hero.subtitle} heroImage={hero.hero_image} paragraph={hero.p}></Hero>
      <Popular></Popular>
      <CallToAction
        title={cta.title}
        body={cta.body}
      />
      <AllFeatures
        title={all_features.title}
      />
      
      {/* <SocialProof></SocialProof> */}
      <Sneakpeek></Sneakpeek>
      {/* <Features
        title={features.title}
        featureItems={features.feature_item}
      /> */}

      <Faq
        title={faqs.title}
        faqItems={faqs.faq_item}
      />
      {/* <Mission
        title={mission.title}
        body={mission.body}
      /> */}
      <CallToAction
        title={cta.title}
        body={cta.body}
      />
      <Integrations />
      {/* <div style={{ padding: 48, background:'#BFF9ED' }}>
        <ContactForm />
      </div> */}
    </Layout>
  )
}

export const query = graphql`
  {
    strapiHomepage {
      basicSeo {
        seoTitle
        seoMetadescription
      }
      all_features {
        title
      }
      cta {
        title
        body
      }
      faqs {
        faq_item {
          question
          answer
          strapi_id
        }
        title
      }
      features {
        title
        feature_item {
          feature_item_seo_title
          feature_item_title
          feature_item_body
          feature_item_image {
            alternativeText
            caption
            url
          }
          strapi_id
        }
      }
      mission {
        title
        body
      }
      popular {
        popular_item {
          popular_item_title
          popular_item_image {
            url
            alternativeText
            caption
          }
          strapi_id
        }
        title
      }
      hero {
        hero_image {
          alternativeText
          url
          caption
        }
        title
        subtitle
        p
      }
    }
  }
`

export default Index;
