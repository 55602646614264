import * as React from "react";
import { Link } from "gatsby";
import Container from "react-bootstrap/esm/Container";
import ReactMarkDown from 'react-markdown'
import Layout from "../components/layout";
import SEO from "../components/seo";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)

export function Blog(props) {
  const node = props.pageContext;
  const { seoBasic, slug, blogCta, body, heroImage, title, quickSummary, next, previous } = node;
  return (
    <Layout>
      {/* <div className="progress-wrapper">
        <div className="progress-container">
          <div className="progress-bar" id="myBar"></div>
        </div>
      </div> */}
      <SEO title={seoBasic.seoTitle} description={seoBasic.seoMetadescription} index={true} follow={true}></SEO>
      <Container>
        <div className="blog-container blog-content">
          <div className="blog-content-wrapper">
            <div className="hero-image">
              {heroImage.url &&
                <img src={heroImage.url} alt={heroImage.alternativeText} title={heroImage.caption} className='img-fluid' />
              }
            </div>
            <div className="breadcrumb">
              <Link to="/"> home </Link> <span>/</span> <Link to="/blogs"> blogs </Link> <span>/</span> {slug.replaceAll('-', ' ')}
            </div>
            <h1>{title}</h1>
            <div id="quick-summary-wrapper">
              <div className="created-wrapper">
                <p id="createdAt"></p>
              </div>
              <h3>In het kort...</h3>
              <p>{quickSummary}</p>
              <span id="time">{body.data.childMarkdownRemark.timeToRead} min</span>
            </div>
            <article id='article'>
              <ReactMarkDown>{body.data.body}</ReactMarkDown>
            </article>
            {blogCta &&
              <section className="contact-wrapper">
                <p>{blogCta.ctaText}</p>
                <a href={blogCta.ctaLink} className="contact-button" target="_blank" rel="noreferrer">{blogCta.ctaButtonTitle}</a>
              </section>
            }
            {(previous || next) &&
              <div className="related-blogs-wrapper">
                <h2 className="text-center">Bekijk ook onze andere blogs</h2>
                <div className="related-blogs">
                  {previous &&
                    <Link to={`/blogs/${previous.slug}`}>
                      <div className="related-arrow">
                        <FontAwesomeIcon icon='chevron-left'></FontAwesomeIcon>
                      </div>
                      <div className="related-title">
                        {previous.title}
                      </div>
                    </Link>
                  }
                  {next &&
                    <Link to={`/blogs/${next.slug}`}>
                      <div className="related-title">
                        {next.title}
                      </div>
                      <div className="related-arrow">
                        <FontAwesomeIcon icon='chevron-right'></FontAwesomeIcon>
                      </div>
                    </Link>
                  }
                </div>
              </div>
            }
          </div>
          {/* <div className="blog-sidebar-wrapper">
          <div className="blog-toc">
            <h3>Content</h3>
            <div dangerouslySetInnerHTML={{ __html: body.data.childMarkdownRemark.tableOfContents }}></div>
          </div>
        </div> */}
        </div>
      </Container>
    </Layout>
  );
}

export default Blog;
