import { useStaticQuery, graphql, Link } from "gatsby"
import Container from "react-bootstrap/esm/Container"

const WikiCategories = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiCategory {
        nodes {
          wiki
          strapi_id
          wikis {
            title
            strapi_id
            slug
          }
        }
      }
    }
  `)

  const Wikies = data.allStrapiCategory.nodes

  return (
    <Container>
      <div className="wiki-row" >
        {Wikies.sort((a, b) => a.wiki.localeCompare(b.wiki)).map((category) => (
          <div key={category.strapi_id}>
            <h2>
              {category.wiki}
            </h2>
            {
              category.wikis.sort((a, b) => a.title.localeCompare(b.title)).map((wiki) => (
                <Link to={wiki.slug} key={wiki.strapi_id}>
                  <div className="wiki-links">
                    {wiki.title}
                    <div className="wiki-links__icon">{`>`}</div>
                  </div>
                </Link>
              ))
            }
          </div>
        )
        )}
      </div>
    </Container>
  )
}

export default WikiCategories;


