import Container from 'react-bootstrap/esm/Container';
import Layout from '../components/layout';
import SEO from '../components/seo';
import CallToAction from '../components/shared/call-to-action';

/* eslint-disable-next-line */
export interface GuideProps { }

export function Guide(props) {
  const nodes = props.pageContext;
  return (
    <Layout>
      <SEO title='Ultimate Guide Car Rental Software' description='Download the Ultimate Guide to Car Rental Software' index={false} follow={false}>
      <Container>
        <div className='guide-wrapper'>
          <div className='guide-content'>
            <h1>{nodes.title}</h1>
            <p>{nodes.description}</p>
          </div>
          <div className='guide-image'>
            <a href={nodes.guide.url}>
              <img src={nodes.guideImage.url} alt={nodes.guideImage.alternativeText} title={nodes.guideImage.caption} className='img-fluid'></img>
            </a>
          </div>
        </div>
      </Container>
      <CallToAction></CallToAction>
      </SEO>
    </Layout>
  );
}

export default Guide;
