import MissionImage from "../../images/homepage/rentvisie-mission.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)
/* eslint-disable-next-line */

export function Mission({title, body}) {
  return (
    <div className="mission__background">
      <div className="mission__icon">
        <FontAwesomeIcon icon="rocket" />
      </div>
      <div className="mission__flex">
        <div className="mission__left">
          <img src={MissionImage} alt='Autoverhuursoftware op een Missie' className="mission__image"></img>
        </div>
        <div className="mission__right">
          <div className="mission__content">
          <h2>{title}</h2>
          <p>{body}</p>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Mission;
