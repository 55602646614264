import Button from 'react-bootstrap/Button';
import CalendlyButton from '../calendly/PopupButton';

export function CallToAction({title, body}) {
  return (
    <div className='cta__background'>
      <h2>{title}</h2>
      <p>
        {body}
      </p>
      <CalendlyButton />
    </div>
  );
}

export default CallToAction;
