import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Container from "react-bootstrap/esm/Container";
import ReactMarkdown from "react-markdown";

const PrivacyTemplate = (props) => {
    const nodes = props.pageContext
    const {
      slug,
      seoBasic,
      content
    } = nodes
    return (
        <Layout>
            <SEO title={seoBasic.seoTitle} description={seoBasic.seoDescription} index={true} follow={true}></SEO>
            <Container>
                <div className="breadcrumb">
                    <a href='/' style={{color:'#000'}}>home</a> <span style={{padding:"0 10px"}}>/</span> {slug}
                </div>
                <div className="privacy-row">
                    <div className="col25-desktop">
                        <div className="contact-box">
                            <h2>Contact</h2>
                            <div style={{padding:"12px 0"}}>
                            <h3>Address</h3>
                            <p>
                            Turbinestraat 6 <br></br> 
                            1014AV Amsterdam <br></br>
                            The Netherlands
                            </p>
                            </div>
                            <div style={{padding:"12px 0"}}>

                            <h3>Digital</h3>
                            <a href='tel:0850602893'>085 06 02 893</a> <br></br>
                            <a href='mailto:support@rentvisie.nl'>support@rentvisie.nl</a>
                            </div>
                        </div>
                    </div>
                    <div className="col75-desktop">
                    <ReactMarkdown>{content.data.content}</ReactMarkdown>
                    </div>
                </div>
            </Container>
        </Layout>
    )
}

export default PrivacyTemplate