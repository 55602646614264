import BusinessModel from "../../images/about/business-model.png"
import ReactMarkdown from "react-markdown";

export function AboutBusinessModel({service}) {
  return (
    <div className="about__business-model-wrapper">
      <div className="about__business-model-content">
        <ReactMarkdown>{service.data.service}</ReactMarkdown>
      </div>
      <div className="about__business-model-image">
        <img src={BusinessModel} alt='Business Model'></img>
      </div>
    </div>
  );
}

export default AboutBusinessModel;
