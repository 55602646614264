exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-autoverhuur-software-tsx": () => import("./../../../src/pages/autoverhuur-software.tsx" /* webpackChunkName: "component---src-pages-autoverhuur-software-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kenteken-opzoeken-tsx": () => import("./../../../src/pages/kenteken-opzoeken.tsx" /* webpackChunkName: "component---src-pages-kenteken-opzoeken-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-resellers-tsx": () => import("./../../../src/pages/resellers.tsx" /* webpackChunkName: "component---src-pages-resellers-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-wiki-js": () => import("./../../../src/pages/wiki.js" /* webpackChunkName: "component---src-pages-wiki-js" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-customers-tsx": () => import("./../../../src/templates/customers.tsx" /* webpackChunkName: "component---src-templates-customers-tsx" */),
  "component---src-templates-guide-tsx": () => import("./../../../src/templates/guide.tsx" /* webpackChunkName: "component---src-templates-guide-tsx" */),
  "component---src-templates-plan-tsx": () => import("./../../../src/templates/plan.tsx" /* webpackChunkName: "component---src-templates-plan-tsx" */),
  "component---src-templates-privacy-tsx": () => import("./../../../src/templates/privacy.tsx" /* webpackChunkName: "component---src-templates-privacy-tsx" */),
  "component---src-templates-services-tsx": () => import("./../../../src/templates/services.tsx" /* webpackChunkName: "component---src-templates-services-tsx" */),
  "component---src-templates-wiki-tsx": () => import("./../../../src/templates/wiki.tsx" /* webpackChunkName: "component---src-templates-wiki-tsx" */)
}

