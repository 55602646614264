
import CalendlyButton from '../calendly/PopupButton';
import Container from 'react-bootstrap/esm/Container';

export function Hero({ heroImage, title, subtitle, paragraph }) {
  return (
    <div className="home-background">
      <Container>
        <div className='hero-flex'>
          <div className='hero-left'>
            <div className='hero-left__content'>
              <div className='hero-left__main-content'>
                <div className='hero-left__seo-heading' style={{justifySelf: 'left'}}>
                  <h1>{title}</h1>
                </div>
                <h2>{subtitle}</h2>
                <p>
                  {paragraph}
                </p>
                {/* <Button className='btn btn-primary'>Gratis Demo</Button> */}
                <CalendlyButton />
              </div>
            </div>
          </div>
          <div className='hero-right'>
            <img src={heroImage.url} alt={heroImage.alternativeText} title={heroImage.caption} className='hero-image img-fluid'></img>
          </div>
        </div>
      </Container>
    </div >
  );
}

export default Hero;
