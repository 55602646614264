import Container from 'react-bootstrap/esm/Container';
import ReactMarkdown from 'react-markdown';
import Layout from '../components/layout';
import { Link } from 'gatsby';

const Wiki = (props) => {
  const nodes = props.pageContext;
  const { title, body, iframe } = nodes;

  return (
    <Layout>
      <div className="blog-content">
        <Container>
          <div className="breadcrumb">
            <Link to="/"> home </Link> <span>/</span>{' '}
            <Link to="/wiki"> wiki </Link> <span>/</span> {title}
          </div>
          <h1>{title}</h1>
          {iframe && (
              <iframe
                src={iframe.src}
                title={iframe.title}
                allow={`accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture`}
                frameBorder="0"
                allowFullScreen={iframe.allowFullScreen}
                width={'100%'}
                height={640}
              />

          )}
          
          <ReactMarkdown>{body?.data?.body}</ReactMarkdown>
          <div className="wiki-return">
            <Link to="/wiki">Terug naar de Wiki</Link>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default Wiki;
