import React from "react";
import Table from 'react-bootstrap/Table'
import Container from "react-bootstrap/esm/Container";
import RotatePhone from "../images/icons/rotate-phone-white.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { TABLEHEADER, TABLEBODY } from './data'

library.add(fas)

export function Packages({ title, body, TABLEHEADER, TABLEBODY }) {
  return (
    <>
      <div className='title-section__light'>
        <h1>{title}</h1>
        <p>
          {body}
        </p>
      </div>
      <div>
        <img src={RotatePhone} alt='Rotate Phone' className="rotate-phone"></img>
        <Container style={{ paddingTop: 24, paddingBottom: 24 }}>
          <Table hover bordered responsive="lg">
            <thead>
              <tr>
                <th>{TABLEHEADER.one}</th>
                <th>
                  BUSINESS
                  <br></br>
                  {TABLEHEADER.two}
                  <br></br>
                  <FontAwesomeIcon icon="circle" style={{ color: '#01E8B8' }} />
                </th>
                <th>
                  BUSINESS
                  <br></br>
                  {TABLEHEADER.three}
                  <br></br>
                  <FontAwesomeIcon icon="circle" style={{ color: '#01E8B8' }} /> <FontAwesomeIcon icon="circle" style={{ color: '#01E8B8' }} />
                </th>
                <th>
                  BUSINESS
                  <br></br>
                  {TABLEHEADER.four}
                  <br></br>
                  <FontAwesomeIcon icon="circle" style={{ color: '#01E8B8' }} /> <FontAwesomeIcon icon="circle" style={{ color: '#01E8B8' }} /> <FontAwesomeIcon icon="circle" style={{ color: '#01E8B8' }} />
                </th>
                <th>
                  BUSINESS
                  <br></br>
                  {TABLEHEADER.five}
                  <br></br>
                  <FontAwesomeIcon icon="circle" style={{ color: '#01E8B8' }} /> <FontAwesomeIcon icon="circle" style={{ color: '#01E8B8' }} /> <FontAwesomeIcon icon="circle" style={{ color: '#01E8B8' }} /> <FontAwesomeIcon icon="circle" style={{ color: '#01E8B8' }} />

                </th>
              </tr>
            </thead>
            <tbody>
              {/* map over all body items */}
              {TABLEBODY.rows.map((bodyItem) => (
                <tr className={`${bodyItem.subHeader ? 'packages-subheader' : ''}`}>
                  <td>{bodyItem.description}</td>
                  <td>
                    {
                      bodyItem.standard === 'v' && <FontAwesomeIcon icon="circle" style={{ color: '#01E8B8' }} />
                    }
                    {
                      bodyItem.standard === 'x' && <FontAwesomeIcon icon="circle" style={{ color: 'gray' }} />
                    }
                    {bodyItem.standard !== 'v' && bodyItem.standard !== 'x' && bodyItem.standard}
                  </td>
                  <td>
                    {
                      bodyItem.professional === 'v' && <FontAwesomeIcon icon="circle" style={{ color: '#01E8B8' }} />
                    }
                    {
                      bodyItem.professional === 'x' && <FontAwesomeIcon icon="circle" style={{ color: 'gray' }} />
                    }
                    {bodyItem.professional !== 'v' && bodyItem.professional !== 'x' && bodyItem.professional}
                  </td>
                  <td>
                    {
                      bodyItem.premium === 'v' && <FontAwesomeIcon icon="circle" style={{ color: '#01E8B8' }} />
                    }
                    {
                      bodyItem.premium === 'x' && <FontAwesomeIcon icon="circle" style={{ color: 'gray' }} />
                    }
                    {bodyItem.premium !== 'v' && bodyItem.premium !== 'x' && bodyItem.premium}
                  </td>
                  <td>
                    {
                      bodyItem.enterprise === 'v' && <FontAwesomeIcon icon="circle" style={{ color: '#01E8B8' }} />
                    }
                    {
                      bodyItem.enterprise === 'x' && <FontAwesomeIcon icon="circle" style={{ color: 'gray' }} />
                    }
                    {bodyItem.enterprise !== 'v' && bodyItem.enterprise !== 'x' && bodyItem.enterprise}
                  </td>
                </tr>
              )
              )}
            </tbody>
          </Table>
        </Container>
      </div>
    </>
  );
}

export default Packages;
