import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import CallToAction from '../components/shared/call-to-action';
import Packages from "../packages/packages";

export function Plan(props) {
  const nodes = props.pageContext
  const {
    seoBasic,
    title,
    body,
    tableHeader,
    tableBody,
    locale,
    cta
  } = nodes

  return (
    <Layout>
      <SEO title={seoBasic.seoTitle} description={seoBasic.seoDescription} index={true} follow={true}></SEO>
      <Packages TABLEHEADER={tableHeader} TABLEBODY={tableBody} title={title} body={body}></Packages>
      <CallToAction title={`${locale === 'en' ? "Would you like to have a quote?" : "Benieuwd naar de prijzen?" }`} body={cta.body} />
    </Layout>
  );
}

export default Plan;
