import Container from "react-bootstrap/esm/Container";
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby";

export function Popular() {
  const data = useStaticQuery(graphql`
    query {
      strapiPopularitem {
        popularItems {
          body
          image {
            caption
            alternativeText
            url
          }
          title
          url
          linkText
        }
      }
    }
  `)
  return (
    <div className="popular__background">
      <Container>
        {data.strapiPopularitem.popularItems.map((item) => (
          <div className="popular-50" style={{ alignItems: 'center' }}>
            <div>
              <h3>{item.title}</h3>
              <p>{item.body}</p>
              <Link to={item.url} className="button btn-secondary-text" style={{ padding: 12, fontSize: 14 }}>{item.linkText}</Link>
            </div>
            <div>
              <img src={item.image.url} alt={item.image.alternativeText} />
            </div>
          </div>
        ))}
      </Container>
    </div>
  );
}

export default Popular;
