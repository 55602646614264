import React from 'react';
import { Link } from 'gatsby';
import Container from 'react-bootstrap/esm/Container';
import Layout from '../components/layout';

const NotFoundPage = () => (
    <Layout>
        <Container>
            <div style={{ minHeight: '80vh' }}>
                <h1>Pagina niet gevonden</h1>
                <Link to='/' className='button btn-secondary-text'>Keer terug naar home</Link>
            </div>
        </Container>
    </Layout>
);

export default NotFoundPage;
