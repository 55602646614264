import { useStaticQuery, graphql, Link } from "gatsby"
import Container from "react-bootstrap/Container";
import RentvisieMobile from "../../images/homepage/all-features-rentvisie.webp"
import AccountingIcon from "../../images/icons/accounting.svg"
import BrokerIcon from "../../images/icons/broker.svg"
import CarSharingIcon from "../../images/icons/car-sharing.svg"
import CheckInIcon from "../../images/icons/check-in.svg"
import ContractIcon from "../../images/icons/contract.svg"
import DashboardIcon from "../../images/icons/dashboard.svg"
import FleetIcon from "../../images/icons/fleet.svg"
import InvoiceIcon from "../../images/icons/invoice.svg"
import PricingIcon from "../../images/icons/pricing.svg"
import StrategyIcon from "../../images/icons/strategy.svg"
import SupportIcon from "../../images/icons/support.svg"
import TimeManagementIcon from "../../images/icons/time-management.svg"
import WebsiteIcon from "../../images/icons/website.svg"



/* eslint-disable-next-line */
export interface AllFeaturesProps { }

export function AllFeatures({ title }) {
  const strapiQuery = useStaticQuery(graphql`
{
  strapiFeature {
    accounting
    broker
    checkin
    cloud
    data
    fleetManagement
    more
    planning
    pricing
    selfService
    signature
    support
    website
    invoice
    linkOnlineCheckin
    linkPlanning
    linkSigning
    linkDynamicPricing
    linkSelfService
    linkData
    linkInvoice
    linkWebsite
    linkAccounting
    linkFleet
    linkCloud
    linkSupport
  }
}
`)
const { 
  accounting, 
  broker, 
  checkin, 
  cloud, 
  data, 
  fleetManagement, 
  more, 
  planning, 
  pricing, 
  selfService, 
  signature, 
  support, 
  website, 
  invoice,
  linkOnlineCheckin,
  linkPlanning,
  linkSigning,
  linkDynamicPricing,
  linkSelfService,
  linkData,
  linkInvoice,
  linkWebsite,
  linkAccounting,
  linkFleet,
  linkCloud,
  linkSupport
} = strapiQuery.strapiFeature
  return (
    <Container className="all-features__wrapper">
      <div className="headlines">
        {/* <div className="headline__highlighted">
            Dit bieden wij
          </div> */}
        <div className="headline__title">
          <h2>
            {title}
          </h2>
        </div>
      </div>
      <div className="all-features__flex">
        <div className="all-features__left all-features__list">
          <ul>
            <li><Link to={`/${linkOnlineCheckin}`}>{checkin} <img src={CheckInIcon} alt="Online check-in" className="img-fluid"></img></Link></li>
            <li><Link to={`/${linkPlanning}`}>{planning} <img src={StrategyIcon} alt="Planning" className="img-fluid"></img></Link></li>
            <li><Link to={`/${linkSigning}`}>{signature} <img src={ContractIcon} alt="Paperless" className="img-fluid"></img></Link></li>
            <li><Link to={`/${linkDynamicPricing}`}>{pricing} <img src={PricingIcon} alt="Dynamic Pricing" className="img-fluid"></img></Link></li>
            <li><Link to={`/${linkSelfService}`}>{selfService} <img src={CarSharingIcon} alt="Car Sharing" className="img-fluid"></img></Link></li>
            <li><Link to="#">{broker} <img src={BrokerIcon} alt="Broker" className="img-fluid"></img></Link></li>
            <li><Link to={`/${linkData}`}>{data} <img src={DashboardIcon} alt="Dashboard" className="img-fluid"></img></Link></li>
          </ul>
        </div>
        <div className="all-features__image">
          <img src={RentvisieMobile} alt='RentVisie Mobiel' className='img-fluid' />
        </div>
        <div className="all-features__right all-features__list">
          <ul>
            <li><Link to={`/${linkInvoice}`}><img src={InvoiceIcon} alt="Invoice" className="img-fluid"></img> {invoice}</Link></li>
            <li><Link to={`/${linkWebsite}`}><img src={WebsiteIcon} alt="Website" className="img-fluid"></img> {website} </Link></li>
            <li><Link to={`/${linkAccounting}`}><img src={AccountingIcon} alt="Accounting" className="img-fluid"></img> {accounting}</Link> </li>
            <li><Link to={`/${linkFleet}`}><img src={FleetIcon} alt="Fleet Management" className="img-fluid"></img> {fleetManagement}</Link></li>
            <li><Link to={`/${linkCloud}`}><img src={TimeManagementIcon} alt="Cloud" className="img-fluid"></img> {cloud}</Link></li>
            <li><Link to={`/${linkSupport}`}><img src={SupportIcon} alt="Support" className="img-fluid"></img> {support}</Link></li>
            <li><img src={CheckInIcon} alt="More features" className="img-fluid"></img> {more} </li>
          </ul>
        </div>
      </div>
    </Container>
  );
}

export default AllFeatures;
