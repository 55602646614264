import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Logo from '../images/logo.png';
import CalendlyButtonNav from './calendly/PopupButtonNav';
import Container from 'react-bootstrap/esm/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Header = () => {
  return (
    <div className="nav-wrapper">
      <Navbar expand="lg" style={{ height: 105 }}>
        <Navbar.Brand>
          <Link to="/">
            <img
              src={Logo}
              alt="RentVisie Logo"
              title="RentVisie - Next Generation Software"
              style={{ width: 224, margin: '0 24px' }}
            ></img>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-toggler" />
        <Navbar.Collapse id="navbar-toggler">
          <Nav className="mx-auto">
            {/* TODO: Add Redirects */}
            <NavItems />
          </Nav>
          <Nav className="ml-auto" style={{ margin: '0 24px' }}>
            <Nav.Link>
              <CalendlyButtonNav />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export const HeaderAds = () => {
  return (
    <div className="nav-wrapper-nonsticky">
      <Container>
        <Navbar expand="lg" style={{ height: 105 }}>
          <Navbar.Brand>
            <Link to="/">
              <img
                src={Logo}
                alt="RentVisie Logo"
                title="RentVisie - Next Generation Software"
                style={{ width: 224 }}
              ></img>
            </Link>
          </Navbar.Brand>

          <Nav className="ml-auto">
            <a href="#contact-form">
              <button className="btn btn-primary">Plan een Demo</button>
            </a>
          </Nav>
        </Navbar>
      </Container>
    </div>
  );
};
const NavItems = () => {
  const data = useStaticQuery(graphql`
    {
      strapiNavbar {
        navItems {
          navItem
          linkTo
          strapi_id
          dropdownItems {
            dropdownItem
            strapi_id
            linkTo
          }
        }
      }
    }
  `);
  return data.strapiNavbar.navItems.map((item, index) =>
    item.dropdownItems ? (
      <NavDropdown title={item.navItem} id={item.navItem} key={`${item.strapi_id}-${index}`}>
        {item.dropdownItems.map((dropdown) => (
          <Link to={`/${dropdown.linkTo}`} className="dropdown-item" key={`${dropdown.strapi_id}`}>
            {dropdown.dropdownItem}
          </Link>
        ))}
      </NavDropdown>
    ) : (
      <Nav.Item key={`${item.id}-${index}1`}>
        <Link to={`/${item.linkTo}`} activeClassName="nav-active">
          {item.navItem}
        </Link>
      </Nav.Item>
    )
  );
};

export default Header;
