import Container from "react-bootstrap/Container";
import AutomationImage from "../../images/homepage/automation.svg";
import CustomerExperienceImage from "../../images/homepage/customer-experience.svg";
import DataDrivenImage from "../../images/homepage/data-driven.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)
/* eslint-disable-next-line */
export interface FeaturesProps { }

export function Features({ title, featureItems }) {
  return (
    <div className="features__background">
      <Container>
        {/* <div className="features__headline">
          <h2>{title}</h2>
        </div> */}
        {featureItems.map((item) => (
          <div className="features__flex">
            <div className="feature__image" key={item.strapi_id}>
              <img src={item.feature_item_image?.url} alt="Automatiseer" className="img-fluid"></img>
            </div>
            <div className="feature__content">
              <div className="feature__seo-title">
                <h2>{item.feature_item_seo_title}</h2>
              </div>
              <div className="feature__headline">
                {item.feature_item_title}
              </div>
              <div className="feature__content">
                <p>
                  {item.feature_item_body}
                </p>
              </div>
            </div>
          </div>
        ))}
      </Container>
      <div className="feature__icon">
        <FontAwesomeIcon icon='play' flip="horizontal"></FontAwesomeIcon>
      </div>
      <div className="feature__icon-two">
        <FontAwesomeIcon icon='play'></FontAwesomeIcon>
      </div>
    </div>
  );
}

export default Features;
