import Container from "react-bootstrap/esm/Container";

const WhyChooseRv = ({ rvusps }) => {
    return (
        <Container>
            <h2 className='why-rentvisie-title'>{rvusps.title}</h2>
            <div className="why-rentvisie-wrapper">
                {rvusps.whychooservitems.slice(0, 5).map((item) => (
                    <div className="why-rentvisie-item" key={item.strapi_id}>
                        <div style={{ display: 'flex' }}> <img src={item.icon.url} alt={item.icon.caption} style={{ width: 32, height: 32, marginRight: 8 }} /> <h3>{item.title}</h3></div>
                        <p style={{ marginTop: 8 }}>{item.description}</p>
                    </div>
                ))}
            </div>
        </Container>
    )
}

export default WhyChooseRv;