import { useStaticQuery, graphql } from "gatsby"

const ButtonText = () => {
  const data = useStaticQuery(graphql`
    {
      strapiCtabutton {
        ctaText
      }
    }
  `)
  return data.strapiCtabutton.ctaText
}

export default ButtonText