
import IntegrationImage from '../../images/homepage/connected.png'
/* eslint-disable-next-line */
export interface IntegrationProps {}

export function Integrations(props: IntegrationProps) {
  return (
    <img src={IntegrationImage} alt='integraties RentVisie' className='integration-image' />
  );
}

export default Integrations;
