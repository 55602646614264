import Logo from "../images/svg/logo-rentvisie-svg.svg"
import Container from 'react-bootstrap/esm/Container';
import CountdownTimer from "../components/countdown/CountdownTimer";
import Unlocked from "../images/icons/unlocked.png"
import SEO from "../components/seo";
import Layout from "../components/layout";

/* eslint-disable-next-line */
export interface LoginProps { }

export function Login(props: LoginProps) {
  const THREE_DAYS_IN_MS = 5 * 1000;
  const NOW_IN_MS = new Date().getTime();

  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;

  return (
    <Layout>
      <SEO index={true} follow={true} title='Login to RentVisie Software' description="Login Page Next Generation Car Rental Software" ></SEO>
      <Container>
        <div className='redirect__wrapper'>
          {/* <img src={Logo} alt='Rentvisie' className="img-fluid" width={96}></img> */}
          <h1>Unlocking Rental Software <img src={Unlocked} alt='unlocked' width={36} style={{ marginBottom: 24 }}></img></h1>
          <CountdownTimer targetDate={dateTimeAfterThreeDays} />
        </div>
      </Container>
    </Layout>
  );
}

export default Login;
