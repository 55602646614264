import axios from 'axios'
import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import Hero from '../components/homepage/hero'
import Layout from '../components/layout'
import SEO from '../components/seo'

const headers = {
    'X-App-Token': 'Q1HwTd9vb2GUYs1xmq1uxQb1I'
}

const LicenseCheck = () => {
    const [licensePlate, setLicensePlate] = React.useState<string>('')
    const [basicDetails, setBasicDetails] = React.useState<any>()
    const [mappedBasicDetails, setMappedBasicDetails] = React.useState<any>()
    const [mappedAdditionalDetails, setMappedAdditionalDetails] = React.useState<any>()
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState('')
    const [additionalDetails, setAdditionalDetails] = React.useState<any>()
    const [show, setShow] = React.useState(false)

    const handleChange = (e) => {
        setLicensePlate(e.target.value.toUpperCase())
    }

    const getLicenseDetails = async () => {
        const licenseRegexp = /^[A-Z0-9]{6}$/
        const isValidLicense = licenseRegexp.test(licensePlate);

        if (!isValidLicense) {
            setError('kenteken niet geldig')
            return;
        }
        setError('')

        try {

            setLoading(true)

            // Get Basic Info
            await axios.get(`https://opendata.rdw.nl/resource/m9d7-ebf2.json?kenteken=${licensePlate}`, {
                headers
            })
                .then((res) => setBasicDetails(res))
                .catch((e) => console.log(e))

            // Get Advanced Info            
            await axios.get(`https://opendata.rdw.nl/resource/8ys7-d773.json?kenteken=${licensePlate}`, {
                headers
            })
                .then((res) => setAdditionalDetails(res))
                .catch((e) => console.log(e))

            setLoading(false)
            executeScroll()
        } catch (e) {
            console.log(e);
        }
    }

    React.useEffect(() => {
        if (basicDetails?.data?.length > 0) {
            const entriesBasicDetails = Object.entries(basicDetails.data[0])
            setMappedBasicDetails(entriesBasicDetails.map(([key, val]) => {
                return (
                    <div className='license-plate-response__line'>
                        <p style={{ fontWeight: 600 }}>{key}</p>
                        <p>{val}</p>
                    </div>
                )
            })
            )
        }
    }, [basicDetails])

    React.useEffect(() => {
        if (additionalDetails) {
            const entriesAdditionalDetails = Object.entries(additionalDetails.data[0])
            setMappedAdditionalDetails(entriesAdditionalDetails.map(([key, val]) => {
                return (
                    <div className='license-plate-response__line' >
                        <p style={{ fontWeight: 600 }}>{key}</p>
                        <p>{val}</p>
                    </div>
                )
            })
            )
        }
    }, [additionalDetails])

    const responseRef = React.useRef(null)
    const executeScroll = () => responseRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' }) 

    return (
        <Layout>
            <SEO
                title='RentVisie | Kenteken Opvragen met RDW Gegevens'
                description='Informatie over jouw voertuig nodig? Gebruik de RentVisie Kenteken Opvraagmodule op deze pagina.'
                follow={true}
                index={true}
            />

            <div className="home-background">
                <Container>
                    <div className='hero-flex'>
                        <div className='hero-left'>
                            <div className='hero-left__content'>
                                <div className='hero-left__main-content'>
                                    <div className='hero-left__seo-heading' style={{ justifySelf: 'left' }}>
                                        <h1>Kenteken gegevens opzoeken</h1>
                                    </div>
                                    <h2>Voer het kenteken op en druk op zoek</h2>
                                    <p>
                                        Vul het kenteken in zonder streepjes
                                    </p>
                                    <div className='license-plate' >
                                        <div className='license-plate__country'>
                                            NL
                                        </div>
                                        <input type='text' className='license-plate__search' maxLength={6} onChange={handleChange} ></input>
                                    </div>
                                    <button type='submit' className='btn btn-secondary' style={{ marginTop: 48 }} onClick={() => {getLicenseDetails()}}> Gegevens Opvragen </button>
                                    {error && <p>{error}</p>}
                                </div>
                            </div>
                        </div>
                        <div className='hero-right'>

                            <img
                                src={'https://rentvisiecms.s3.eu-west-1.amazonaws.com/rv_shortlease_4cb7aafc07.webp'}
                                alt={'find your vehicle'} title={'find your vehicle with our license plate'} className='hero-image img-fluid'></img>
                        </div>
                    </div>
                </Container>
            </div >
            <Container>
                <div className='license-plate-response' ref={responseRef}>
                    {loading &&
                        <p>Ogenblik geduld...</p>
                    }

                    {
                        (mappedBasicDetails && mappedAdditionalDetails) &&
                        <>
                            <h2>Belangrijkste gegevens</h2>
                            <div className='license-plate-response__line' >
                                <p style={{ fontWeight: 600 }}>Kenteken</p>
                                <p>{basicDetails.data[0].kenteken ? basicDetails.data[0].kenteken : 'Onbekend'}</p>
                            </div>
                            <div className='license-plate-response__line' >
                                <p style={{ fontWeight: 600 }}>Brandstof</p>
                                <p>{additionalDetails.data[0].brandstof_omschrijving ? additionalDetails.data[0].brandstof_omschrijving : 'Onbekend'}</p>
                            </div>
                            <div className='license-plate-response__line' >
                                <p style={{ fontWeight: 600 }}>Vervaldatum APK</p>
                                <p>{basicDetails.data[0].vervaldatum_apk_dt ? `${new Date(basicDetails.data[0].vervaldatum_apk_dt).toLocaleDateString()}` : 'Onbekend'}</p>
                            </div>
                            <div className='license-plate-response__line' >
                                <p style={{ fontWeight: 600 }}>Fiscale Waarde</p>
                                <p>{basicDetails.data[0].catalogusprijs ? `€ ${basicDetails.data[0].catalogusprijs}` : 'Onbekend'}</p>
                            </div>
                            <div className='license-plate-response__line' >
                                <p style={{ fontWeight: 600 }}>Bruto BPM</p>
                                <p>{basicDetails.data[0].bruto_bpm ? `€ ${basicDetails.data[0].bruto_bpm}` : 'Onbekend'}</p>
                            </div>
                            <div className='license-plate-response__line' >
                                <p style={{ fontWeight: 600 }}>CO2 Uitstoot</p>
                                <p>{additionalDetails.data[0].co2_uitstoot_gecombineerd ? `${additionalDetails.data[0].co2_uitstoot_gecombineerd} g/km` : 'Onbekend'}</p>
                            </div>
                            {!show &&
                                <div className='license-plate-response__show' onClick={() => setShow(true)}>
                                    <p>Toon alle gegevens {'>>'}</p>
                                </div>
                            }
                        </>
                    }

                    {show && (
                        mappedBasicDetails && (
                            <>
                                <h2>Basis Gegevens</h2>
                                {mappedBasicDetails}
                            </>
                        )
                    )}

                    {show && (
                        mappedAdditionalDetails && (
                            <>
                                <h2>Brandstof & CO2</h2>
                                {mappedAdditionalDetails}
                            </>)
                    )}
                </div>
            </Container>




        </Layout>
    )
}

export default LicenseCheck