import React from 'react';
import { navigate } from 'gatsby';
import { useCountdown } from '../../hooks/useCountdown';
import Redirects from '../../constants/redirects.json'
import Ok from "../../images/icons/ok.png"

const ShowCounter = ({ seconds }) => {
  return (
    <div className='countdown'>
      <p>{seconds}</p>
    </div>
  );
};

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
      navigate(Redirects.software.url)
      return (
          <img src={Ok} alt='unlocked' width={96}></img>
      )
  } else {
    return (
      <ShowCounter seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;
