import Van from "../../images/about/van.png"
import ReactMarkdown from "react-markdown";

export function AboutMission({mission}) {
  return (
    <div className="about-mission__wrapper">
      <div className="about-mission__image">
        <img src={Van} alt='Bestelbus'></img>
      </div>
      <div className="about-mission__content">
        <ReactMarkdown>{mission.data.mission}</ReactMarkdown>
      </div>
    </div>
  );
}

export default AboutMission;
