import React, { useState } from 'react';
import Select from 'react-select';

export interface Option {
  value: string;
  label: string;
  filter?: string;
}

interface SelectFieldSingleProps {
  options: Option[];
  selectedOption: Option;
  setSelectedOption: (option: Option) => void;
}


const SelectFieldSingle: React.FC<SelectFieldSingleProps> = ({ options, setSelectedOption, selectedOption }) => {

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      // Overwrittes the different states of border
      background: '#ececec',
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      '&:hover': {
        // Overwrittes the different states of border
        cursor: 'pointer',
        borderColor: '#ececec',
      },
      border: 'none',
      height: 48,
      fontWeight: 'normal',
      fontSize: 14,
      marginBottom: 12
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 300,
      borderBottom: '1px dotted black',
      color: state.selectProps.menuColor,
      '&:hover': { cursor: 'pointer' },
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted black',
      fontWeight: state.isSelected ? 'bold' : 'normal',
      background: state.isSelected ? 'gray' : 'white',
      color: state.isSelected ? 'white' : 'black',
      padding: 12,
      '&:active': { color: 'black' },
      '&:hover': { cursor: 'pointer', background: 'black', color: 'white' },
    }),
  };

  return (
    <Select
      name="software"
      value={selectedOption}
      // placeholder="Maak een keuze"
      onChange={setSelectedOption}
      options={options}
      className="react-select-container"
      classNamePrefix="react-select"
      styles={customStyles}
      isSearchable={false}
    />
  );
};

export default SelectFieldSingle;
