import Office from "../../images/about/office.webp"
import ReactMarkdown from "react-markdown";

export function AboutHero({ title, body }) {
  return (
    <>
    <div className='title-section__light'>
      <h1>{title}</h1>
    </div>
      <div className="about__hero-wrapper" style={{marginTop:20}}>
        <div className="about__hero-content">
          <ReactMarkdown>{body.data.introduction}</ReactMarkdown>
        </div>
        <div className="about__hero-image">
          <img src={Office} alt='Jeroen'></img>
        </div>
      </div>
      </>
  );
}

export default AboutHero;
