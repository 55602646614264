import React from "react";
import { PopupModal } from "react-calendly";
import ButtonText from "./ButtonText";

class CalendlyButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = () => {
    this.setState({ isOpen: true })
    if (typeof window !== `undefined`) {
      window.dataLayer.push({
        'event': 'cta_click',
      });
    }
  }

  render() {
    if (typeof window !== `undefined`) { // or typeof document !== 'undefined'
      return (
        <div id="root">
          <button
            onClick={() => this.handleClick()}
            className='btn btn-secondary'
            style={{ width: 250 }}
          >
            <ButtonText />
          </button>
          <PopupModal
            url="https://calendly.com/sales-rentvisie"
            // pageSettings={this.props.pageSettings}
            utm={{
              utmCampaign: 'RentVisie',
              utmContent: 'Book a Demo',
              utmMedium: 'Web',
              utmSource: 'Web',
              utmTerm: 'Web'
            }}
            // prefill={this.props.prefill}
            onModalClose={() => this.setState({ isOpen: false })}
            open={this.state.isOpen}
            className={`${this.state.isOpen && 'test'}`}
            /*
              * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
              * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
            */
            rootElement={document.getElementById("root")}
          />
        </div>
      );
    }
    return null
  }
}

export default CalendlyButton