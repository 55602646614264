import { useForm, ValidationError } from "@formspree/react";
import Container from "react-bootstrap/esm/Container";
import CalendlyButton from "./calendly/PopupButton";

export default function ContactForm() {
  const [state, handleSubmit] = useForm("xpzbenye");

  if (state.succeeded) {
    return (
      <Container>
        <h2>Gelukt!</h2>
        <p>
          Bedankt voor het indienen, wij nemen zo snel mogelijk contact op.
        </p>
        <p>Klik op de knop hieronder om <span className='bold'>direct een afspraak</span> in te plannen.</p>
        <CalendlyButton />
      </Container>
    )
  }
  return (
    <Container style={{textAlign:'center'}}>

      <form onSubmit={handleSubmit} className='demo-form'>
      <h2>Plan een demo</h2>
      <p>
        Laat jouw contactgegevens achter om een demo in te plannen.
      </p>
        <div>
          <label htmlFor="name">Naam</label>
          <input id="name" type="text" name="name" placeholder="Jeroen Swart" required />
          <ValidationError prefix="Name" field="name" errors={state.errors} />
        </div>
        <div>
          <label htmlFor="name">Bedrijf</label>
          <input id="company" type="text" name="company" placeholder="RentVisie" required />
          <ValidationError prefix="Company" field="company" errors={state.errors} />
        </div>
        <div>
          <label htmlFor="phone">Telefoon</label>
          <input id="phone" type="tel" name="phone" placeholder="085 06 02 893" required />
          <ValidationError prefix="Phone" field="phone" errors={state.errors} />
        </div>
        <div>
          <label htmlFor="email">Email Address</label>
          <input id="email" type="email" name="email" placeholder="info@rentvisie.nl" required />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </div>
        <div>
          <label htmlFor="message">Opmerkingen</label>
          <textarea id="message" name="message" placeholder="Ik ben specifiek geïnteresseerd in de Dynamic Pricing Module." />
          <ValidationError prefix="Message" field="message" errors={state.errors} />
        </div>
        <div style={{ paddingTop: 20 }}>
          <button type="submit" className="btn btn-primary" disabled={state.submitting} >
            Aanvragen
          </button>
        </div>
        <ValidationError errors={state.errors} />
      </form>
    </Container>
  );
}