import { Option } from './select-field-single';

export const SOFTWARE_OPTIONS: Option[] = [
  { value: 'rentvisie', label: 'RentVisie' },
  { value: 'traci', label: 'Traci' },
  { value: 'exact', label: 'Exact' },
  { value: 'website', label: 'Website' },
  { value: 'other', label: 'Overig' },
];

export const MODULE_OPTIONS: Option[] = [
  { value: 'operational', label: 'Operationele software', filter: 'rentvisie' },
  { value: 'inspection', label: 'Uitgifte app', filter: 'rentvisie' },
  { value: 'tracktrace', label: 'tracktrace', filter: 'traci' },
  { value: 'cms', label: 'cms', filter: 'website' },
  { value: 'feature', label: 'features', filter: 'website' },
  { value: 'stripe', label: 'stripe', filter: 'other' },
  { value: '24id', label: '24id', filter: 'other' },
  { value: 'crimimail', label: 'crimimail', filter: 'other' },
  { value: 'klantportaal', label: 'klantportaal', filter: 'rentvisie' },
  { value: 'kycportaal', label: 'KYC Portaal', filter: 'rentvisie' },
  { value: 'rentalapp', label: 'Huur/ self-service app', filter: 'rentvisie' },
  { value: 'sync', label: 'synchronisatie', filter: 'exact' },
];

export const PRIORITY_LEVELS: Option[] = [
  // { value: 'high', label: 'High - immediate attention !!!'},
  // { value: 'medium', label: 'Medium - important but the house is not on fire'},
  // { value: 'low', label: 'Low - its still important, just look at it asap'},
  { value: 'low priority', label: 'Laag' },
  { value: 'medium priority', label: 'Gemiddeld' },
  { value: 'urgent priority', label: 'Urgent' },
];
