import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Popular from '../components/homepage/popular';
import Mission from '../components/homepage/mission';
import Features from '../components/homepage/features';
import Container from 'react-bootstrap/esm/Container';
import AllFeatures from '../components/homepage/all-features';
import Sneakpeek from '../components/homepage/sneakpeek';
import Faq from '../components/homepage/faq';
import Integrations from '../components/homepage/integrations';
import SEO from '../components/seo';
import StructuredData from "../schema/rentalsoftware.json"
import ContactForm from '../components/ContactForm';
import { HeaderAds } from '../components/navbar';
import HeroImage from '../images/homepage/hero_rentvisie.webp'
import UpArrow from "../images/icons/chevron-up.png"
import { FooterAds } from '../components/footer';
export function Index({ data }) {
  const {
    basicSeo,
    all_features,
    cta,
    faqs,
    features,
    mission,
    popular,
    hero
  } = data.strapiHomepage
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };
  return (
    <>
      <HeaderAds />
      <SEO title={basicSeo.seoTitle} description={basicSeo.seoMetadescription} structuredData={StructuredData} index={false} follow={false}></SEO>
      <Container>
        <div className="home-background">
          <div className='hero-flex'>
            <div className='hero-left'>
              <div className='hero-left__content'>
                <div className='hero-left__seo-heading'>
                  <h1>{hero.title}</h1>
                </div>
                <div className='hero-left__main-content'>
                  <h2>{hero.subtitle}</h2>
                  <p>
                    {hero.paragraph}
                  </p>
                  {/* <Button className='btn btn-primary'>Gratis Demo</Button> */}
                  <div className='hero-buttons'>
                    <a href='#contact-form'>
                      <button className='btn btn-primary'>Plan een Demo</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='hero-right'>
              <img src={HeroImage} alt='RentVisie Verhuursoftware' className='hero-image img-fluid'></img>
            </div>
          </div>
        </div>
      </Container>
      <Popular title={popular.title} popularItems={popular.popular_item}></Popular>
      <Mission
        title={mission.title}
        body={mission.body}
      />
      <div className='cta__background'>
        <h2>Meer weten over onze autoverhuursoftware?</h2>
        <p>
          Vul het contactformulier in en wij nemen spoedig contact op.
        </p>
        <a href='#contact-form'>
          <button className='btn btn-primary'>Plan een Demo</button>
        </a>
      </div>
      {/* <SocialProof></SocialProof> */}
      <Features
        title={features.title}
        featureItems={features.feature_item}
      />

      <AllFeatures
        title={all_features.title}
      />
      <Sneakpeek></Sneakpeek>
      <Faq
        title={faqs.title}
        faqItems={faqs.faq_item}
      />
      <Integrations />
      <section id='contact-form'>
        <div className='cta__background'>
          <ContactForm />
        </div>
      </section>
      {showButton && (
        <button onClick={scrollToTop} className="back-to-top">
          <img src={UpArrow} alt="^" width={18} height={18} />
        </button>
      )}
      <FooterAds />
    </>
  )
}

export const query = graphql`
  {
    strapiHomepage {
      basicSeo {
        seoTitle
        seoMetadescription
      }
      all_features {
        title
      }
      cta {
        title
        body
      }
      faqs {
        faq_item {
          question
          answer
          strapi_id
        }
        title
      }
      features {
        title
        feature_item {
          feature_item_seo_title
          feature_item_title
          feature_item_body
          feature_item_image {
            alternativeText
            caption
            url
          }
          strapi_id
        }
      }
      mission {
        title
        body
      }
      popular {
        popular_item {
          popular_item_title
          popular_item_image {
            url
            alternativeText
            caption
          }
          strapi_id
        }
        title
      }
      hero {
        hero_image {
          alternativeText
          url
          caption
        }
        title
        subtitle
        p
      }
    }
  }
`

export default Index;
