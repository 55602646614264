import React from "react";
import SEO from "../components/seo";
import Hero from "../components/homepage/hero";
import Layout from "../components/layout";
import Features from '../components/homepage/features';
import CallToAction from '../components/shared/call-to-action';
import AllFeatures from '../components/homepage/all-features';
import Sneakpeek from '../components/homepage/sneakpeek';
import Faq from '../components/homepage/faq';
import Integrations from "../components/homepage/integrations";
import WhyChooseRv from "../components/shared/why-choose-rv";
import Screenshots from "../components/screenshots";

export function Services(props) {
  const nodes = props.pageContext
  const {
    serviceBasicSeo,
    serviceHero,
    serviceFeatures,
    serviceFaqs,
    serviceCta,
    serviceAllFeatures,
    serviceScreenshots,
    rvusps
  } = nodes

  return (
    <Layout>
      <SEO title={serviceBasicSeo.seoTitle} description={serviceBasicSeo.seoMetadescription} index={true} follow={true}></SEO>
      <Hero title={serviceHero.title} subtitle={serviceHero.subtitle} paragraph={serviceHero.p} heroImage={serviceHero.hero_image}></Hero>
      {/* <SocialProof></SocialProof> */}
      {rvusps &&
        <WhyChooseRv rvusps={rvusps} />
      }
      {serviceFeatures &&
        <Features
          title={serviceFeatures.title}
          featureItems={serviceFeatures.feature_item}
        />
      }

      {(serviceCta && serviceCta.title && serviceCta.body) &&
        <CallToAction
          title={serviceCta.title}
          body={serviceCta.body}
        />
      }

      {serviceAllFeatures &&
        <AllFeatures
          title={serviceAllFeatures.title}
        />
      }
      {/* TODO: Add Sneakpeak to CMS */}
      {serviceScreenshots
        ? <Screenshots screenshots={serviceScreenshots} />
        : serviceAllFeatures &&
        <Sneakpeek></Sneakpeek>
      }
      {(serviceFaqs && serviceFaqs.title && serviceFaqs.faq_item) &&
        <Faq
          title={serviceFaqs.title}
          faqItems={serviceFaqs.faq_item}
        />
      }

      {(serviceCta && serviceCta.title && serviceCta.body) &&
        <CallToAction
          title={serviceCta.title}
          body={serviceCta.body}
        />
      }

      <Integrations />
    </Layout>
  );
}

export default Services;
