import AllFeatures from "../components/homepage/all-features"
import Faq from "../components/homepage/faq"
import Hero from "../components/homepage/hero"
import Integrations from "../components/homepage/integrations"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/shared/call-to-action"
import WhyChooseRv from "../components/shared/why-choose-rv"

const Customers = (props) => {
    const nodes = props.pageContext
    const {
        seoBasic,
        hero,
        faqs,
        cta,
        rvusps,
        locale
    } = nodes
    console.log(locale)
    return (
        <Layout>
            <SEO title={seoBasic.seoTitle} description={seoBasic.seoDescription} index={true} follow={true}></SEO>
            <Hero title={hero.title} subtitle={hero.subtitle} paragraph={hero.p} heroImage={hero.hero_image} />
            {/* TODO: Add video */}
            <WhyChooseRv rvusps={rvusps} />
            <AllFeatures title={locale === 'en' ? "Discover the engine behind our rental software solutions" : "Ontdek de motor achter onze verhuursoftware oplossingen"} />
            <CallToAction
                title={cta.title}
                body={cta.body}
            />
            <Faq title={faqs.title} faqItems={faqs.faq_item}></Faq>
            <Integrations />
            {/* <h2 style={{ textAlign: 'center', paddingTop: '100px' }}>Een greep uit onze producten</h2>
            <Popular></Popular> */}
        </Layout>
    )
}

export default Customers