import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Logo from '../images/rentvisie-logo-alt.webp';

const Footer = () => (
  <footer className="page-footer font-small">
    <div className="container-fluid text-center text-md-left">
      <div className="row">
        <div className="col-md-6 my-auto">
          <img src={Logo} alt="RentVisie Logo" style={{ maxWidth: 178 }}></img>
          <ul className="list-unstyled">
            <li>Tijnmuiden 59</li>
            <li>1046AK Amsterdam</li>
            <li>KvK: 75207419</li>
            <li>
              Tel: <a href="tel:0850602893">085 06 02 893</a>
            </li>
          </ul>
        </div>

        {
          <div className="col-md-3 mb-md-0 mb-3">
            <h3 className="text-uppercase">Overig</h3>
            <ul className="fa-ul">
              <FooterLinks></FooterLinks>
            </ul>
          </div>
        }
      </div>
    </div>
  </footer>
);

export const FooterAds = () => (
  <footer className="page-footer font-small">
    <div className="container-fluid text-center text-md-left">
      <div className="row">
        <div className="col-md-6 my-auto">
          <img src={Logo} alt="RentVisie Logo" style={{ maxWidth: 178 }}></img>
          <ul className="list-unstyled">
            <li>Tijnmuiden 59</li>
            <li>1046AK Amsterdam</li>
            <li>KvK: 75207419</li>
            <li>
              Tel: <a href="tel:0850602893">085 06 02 893</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

const FooterLinks = () => {
  const data = useStaticQuery(graphql`
    {
      strapiFooter {
        navItems {
          navItem
          linkTo
          strapi_id
        }
      }
    }
  `);
  return data.strapiFooter.navItems.map((item) => (
    <li key={item.strapi_id}>
      <Link to={`/${item.linkTo}`}>{item.navItem}</Link>
    </li>
  ));
};

export default Footer;
