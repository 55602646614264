import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import '@fontsource/roboto'; // Defaults to weight 400 with all styles included.

const wrapRootElement = ({ element }) => (
  <GoogleReCaptchaProvider reCaptchaKey="6LfmIwcpAAAAAMbOtPqOzrpKTFPSqVN_QNbNiELZ">
    {element}
  </GoogleReCaptchaProvider>
);

export { wrapRootElement }

