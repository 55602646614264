import React, { ChangeEvent, FormEvent, useEffect } from 'react';
import Select from 'react-select';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from 'react-bootstrap/esm/Container';
import axios from 'axios';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import SelectFieldSingle, {
  Option,
} from '../components/form/select-field-single';
import {
  MODULE_OPTIONS,
  PRIORITY_LEVELS,
  SOFTWARE_OPTIONS,
} from '../components/form/select-options';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

interface FormData {
  firstName: string;
  lastName: string;
  companyName: string;
  phoneNumber: string;
  email: string;
  subject: string;
  description: string;
}

const SupportPage = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [loading, setLoading] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [error, setError] = React.useState('');
  const [valid, setValid] = React.useState(false);

  const [softwareType, setSoftwareType] = React.useState<Option>(
    SOFTWARE_OPTIONS[0]
  );

  const [modules, setModules] = React.useState(
    MODULE_OPTIONS.filter((option) => option.filter === softwareType.value)
  );
  const [module, setModule] = React.useState(MODULE_OPTIONS[0]);
  const [prio, setPrio] = React.useState(PRIORITY_LEVELS[0]);

  const [step, setStep] = React.useState(0);

  useEffect(() => {
    setModules(
      MODULE_OPTIONS.filter((option) => option.filter === softwareType.value)
    );
  }, [softwareType]);

  useEffect(() => {
    setModule(modules[0]);
  }, [modules]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const supportCustomer = localStorage.getItem('supportCustomer');
      if (!supportCustomer) return;

      const parsedCustomer = JSON.parse(supportCustomer);

      setFormData({
        ...formData,
        ...parsedCustomer,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emptyForm: FormData = {
    firstName: '',
    lastName: '',
    companyName: '',
    phoneNumber: '',
    email: '',
    subject: '',
    description: '',
  };

  const [formData, setFormData] = React.useState<FormData>(emptyForm);

  const data = useStaticQuery(graphql`
    query {
      strapiSupport {
        title
        subtitle
        description {
          data {
            description
          }
        }
        seo {
          seoTitle
          seoMetadescription
        }
        supportForm {
          submitError
          submitConfirmed
          submit
          subjectPlaceholder
          subject
          phonePlaceholder
          phone
          lastNamePlaceholder
          lastName
          firstNamePlaceholder
          firstName
          emailPlaceholder
          email
          descriptionPlaceholder
          description
          companyPlaceholder
          companyName
          priority
          fillFieldsWarning
          nextButton
          prevButton
          privacyPolicyConfirmation
        }
      }
    }
  `);

  const item = data.strapiSupport;

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNext = () => {
    setError('');
    if (!formData.subject || !formData.description) {
      return;
    }
    setStep(1);
  };

  useEffect(() => {
    if (
      formData.subject ||
      formData.description ||
      formData.firstName ||
      formData.lastName ||
      formData.companyName ||
      formData.phoneNumber ||
      formData.email
    ) {
      setValid(true);
    }
  }, [
    formData.subject,
    formData.description,
    formData.firstName,
    formData.lastName,
    formData.companyName,
    formData.phoneNumber,
    formData.email,
  ]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      alert('noRecaptcha executed');
      return;
    }
    const token = await executeRecaptcha('submit');

    if (!token) {
      alert('cant confirm token');
    }

    if (
      !formData.subject ||
      !formData.description ||
      !formData.firstName ||
      !formData.lastName ||
      !formData.companyName ||
      !formData.phoneNumber ||
      !formData.email
    ) {
      alert(item.supportForm.fillFieldsWarning);
      return;
    }

    // const url = 'https://followup-processor.herokuapp.com/external';
    setLoading(true);

    try {
      const supportCustomer = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        companyName: formData.companyName,
        phoneNumber: formData.phoneNumber,
        email: formData.email,
      };
      await axios.post('https://api.eu.production.rentvisie.com/api/v2/clients/rentvisie/email', {
        ...supportCustomer,
        priority: prio.value.toUpperCase(),
        softwareType: softwareType.value.toUpperCase(),
        module: module.value.toUpperCase(),
        description: formData.description,
        subj: formData.subject,
        token,
      });

      localStorage.setItem('supportCustomer', JSON.stringify(supportCustomer));

      // await axios.post(url, postData, {
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'X-FU-PIPELINE': '310b3a47-e31a-406d-ab7d-e6bb3e63d5c7',
      //   },
      // });

      setFormData({
        ...emptyForm,
        ...supportCustomer,
      });
      setSubmitted(true);
      setTimeout(() => {
        setSubmitted(false)
      }, 3000)
      setError('');
      setStep(0);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(error);
      setError('Something went wrong...please try again');
    }

    setLoading(false);
  };

  return (
    <Layout>
      <SEO
        title={item.seo.seoTitle}
        description={item.seo.seoDescription}
        follow={true}
        index={false}
      />
      <div className="home-background">
        <Container>
          <div className="hero-flex" style={{ height: 'unset' }}>
            <div className="hero-left">
              <div className="hero-left__content">
                <div className="hero-left__main-content">
                  <div
                    className="hero-left__seo-heading"
                    style={{ justifySelf: 'left' }}
                  >
                    <h1>{item.title}</h1>
                    <h2>{item.subtitle}</h2>
                    <ReactMarkdown>
                      {item.description.data.description}
                    </ReactMarkdown>
                  </div>
                </div>
              </div>
            </div>
            <div className="hero-right">
              <form
                className="demo-form"
                style={{ width: '100%' }}
                onSubmit={handleSubmit}
              >
                {/* Initial part */}
                {step === 0 && (
                  <>
                    <div className="demo-form-row">
                      <label htmlFor="message">{'Software Type'}</label>
                      <SelectFieldSingle
                        options={SOFTWARE_OPTIONS}
                        setSelectedOption={setSoftwareType}
                        selectedOption={softwareType}
                      />
                    </div>

                    <div className="demo-form-row">
                      <label htmlFor="message">{'Module'}</label>
                      <SelectFieldSingle
                        options={modules}
                        setSelectedOption={setModule}
                        selectedOption={module}
                      />
                    </div>

                    <div className="demo-form-row">
                      <label htmlFor="message">
                        {item.supportForm.priority}
                      </label>
                      <SelectFieldSingle
                        options={PRIORITY_LEVELS}
                        setSelectedOption={setPrio}
                        selectedOption={prio}
                      />
                    </div>

                    <div className="demo-form-row">
                      <label htmlFor="message">
                        {item.supportForm.subject}
                      </label>
                      <input
                        id="subject"
                        type="text"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        minLength={6}
                        placeholder={item.supportForm.subjectPlaceholder}
                      />
                    </div>

                    <div className="demo-form-row">
                      <label htmlFor="message">
                        {item.supportForm.description}
                      </label>
                      <textarea
                        rows={4}
                        minLength={20}
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        placeholder={item.supportForm.descriptionPlaceholder}
                      />
                    </div>

                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => handleNext()}
                    >
                      {item.supportForm.nextButton}
                    </button>
                    {submitted && (
                      <p style={{ paddingTop: 6, textAlign: 'center' }}>
                        {item.supportForm.submitConfirmed}
                      </p>
                    )}
                  </>
                )}

                {step === 1 && (
                  <>
                    <div className="demo-form-row">
                      <label htmlFor="name">{item.supportForm.firstName}</label>
                      <input
                        id="firstName"
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        placeholder={item.supportForm.firstNamePlaceholder}
                        required
                      />
                    </div>
                    <div className="demo-form-row">
                      <label htmlFor="name">{item.supportForm.lastName}</label>
                      <input
                        id="lastName"
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        placeholder={item.supportForm.lastNamePlaceholder}
                        required
                      />
                    </div>
                    <div className="demo-form-row">
                      <label htmlFor="name">
                        {item.supportForm.companyName}
                      </label>
                      <input
                        id="companyName"
                        type="text"
                        name="companyName"
                        value={formData.companyName}
                        onChange={handleChange}
                        placeholder={item.supportForm.companyPlaceholder}
                        required
                      />
                    </div>
                    <div className="demo-form-row">
                      <label htmlFor="phone">{item.supportForm.phone}</label>
                      <input
                        id="phoneNumber"
                        type="tel"
                        minLength={8}
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        placeholder={item.supportForm.phonePlaceholder}
                        required
                      />
                    </div>
                    <div className="demo-form-row">
                      <label htmlFor="email">{item.supportForm.email}</label>
                      <input
                        id="email"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder={item.supportForm.emailPlaceholder}
                        required
                      />
                    </div>
                    <p className="small">
                      {item.supportForm.privacyPolicyConfirmation}{' '}
                      <Link to="/privacy-policy">privacy policy</Link>.
                    </p>

                    <div
                      style={{
                        paddingTop: 20,
                        paddingBottom: 20,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <button
                        type="button"
                        onClick={() => setStep(0)}
                        className="btn btn-secondary-text"
                      >
                        {item.supportForm.prevButton}
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading || !valid}
                      >
                        {!loading ? item.supportForm.submit : 'Submitting...'}
                      </button>
                    </div>
                    
                    {error && (
                      <p style={{ paddingTop: 6, textAlign: 'center' }}>
                        {item.supportForm.submitError}
                      </p>
                    )}
                  </>
                )}
              </form>{' '}
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default SupportPage;
