import { Carousel } from 'react-responsive-carousel';
import { useStaticQuery, graphql } from "gatsby"
import Container from 'react-bootstrap/Container';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


export function Sneakpeek() {
  const data = useStaticQuery(graphql`
    {
      strapiScreenshot {
        title
        body
        images {
          url
          alternativeText
          caption
        }
      }
    }
  `)
  const {title, body, images} = data.strapiScreenshot 
  return (
    <div className='screenshots__background'>
      <Container className='screenshots__wrapper'>
        <h2>{title}</h2>
        <p>{body}</p>
        <Carousel
          showStatus={false}
          className='carousel'
          autoPlay={true}
        >
          {images.map((image) => (
            <div>
              <img src={image.url} alt={image.alternativeText} title={image.caption} className='img-fluid' />
            </div>
          ))}
        </Carousel>
      </Container>
    </div>
  );
}


export default Sneakpeek;
