import Container from "react-bootstrap/Container";
import IntegrationImage from "../../images/homepage/connected.png"
/* eslint-disable-next-line */
export interface FaqProps { }

export function Faq({ title, faqItems }) {
  return (
    <Container className='faq__wrapper'>
      <div className="headlines">
        <div className="headline__highlighted">
          FAQ
        </div>
        <div className="headline__title">
          <h2>
            {title}
          </h2>
        </div>
      </div>
      {faqItems.map((item) => (
        <div key={item.strapi_id}>
          <hr></hr>
          <p className='question'>
            {item.question}
          </p>
          <p className="answer">
            {item.answer}
          </p>
        </div>
      ))}
    </Container>
  );
}

export default Faq;
