import AboutBusinessModel from '../components/about/about-business-model';
import AboutHero from '../components/about/about-hero';
import AboutMission from '../components/about/about-mission';
import AboutPeople from '../components/about/about-people';
import CallToAction from '../components/shared/call-to-action';
import Layout from '../components/layout';
import SEO from "../components/seo";

export function About(props) {
  const nodes = props.pageContext
  const {
    seoBasic,
    title,
    introduction,
    mission,
    service,
    team,
    cta
  } = nodes
  return (
    <Layout>
      <SEO title={seoBasic.seoTitle} description={seoBasic.seoDescription} index={true} follow={true}></SEO>
      <AboutHero title={title} body={introduction} />
      <AboutMission mission={mission} />
      <AboutBusinessModel service={service}/>
      <AboutPeople team={team} />
      <CallToAction title={cta.title} body={cta.body} />
    </Layout>
  )
}

export default About;
