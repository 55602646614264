import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from 'react-bootstrap/esm/Container';
import CalendlyButton from '../components/calendly/PopupButton';
import Faq from '../components/homepage/faq';
import { graphql, useStaticQuery } from 'gatsby';
import { number } from 'prop-types';

interface UspItem {
  uspTitle: string;
  uspDescription: string;
  strapi_id: number;
}

const ResellerPage = () => {
  const query = useStaticQuery(graphql`
    query {
      strapiReseller {
        usps {
          title
          uspItems {
            uspTitle
            uspDescription
            strapi_id
          }
        }
        faqs {
          title
          faq_item {
            answer
            question
            strapi_id
          }
        }
        seo {
          seoTitle
          seoMetadescription
        }
        cta {
          title
          body
        }
        description
        subTitle
        title
      }
    }
  `)

  const data = query.strapiReseller;
  return (
    <Layout>
      <SEO title={data.seo.seoTitle} description={data.seo.seoMetadescription} index={true} follow={true} />
      <div className="home-background">
        <Container>
          <div className="hero-flex">
            <div className="hero-left">
              <div className="hero-left__content">
                <div className="hero-left__main-content">
                  <div
                    className="hero-left__seo-heading"
                    style={{ justifySelf: 'left' }}
                  >
                    <h1>{data.title}</h1>
                  </div>
                  <h2>{data.subTitle}</h2>
                  <p>
                    {data.description}
                  </p>
                  <CalendlyButton></CalendlyButton>
                </div>
              </div>
            </div>
            <div className="hero-right">
              <div
                className="hero-image"
                style={{ padding: '4rem', width: '100%' }}
              >
                <h3>{data.usps.title}</h3>
                <ul>
                  {data.usps.uspItems.map((item: UspItem) => (
                    <li key={item.strapi_id}>
                    <span>{item.uspTitle}</span>
                    <p
                      style={{
                        margin: '1rem 0',
                        fontWeight: 400,
                        fontStyle: 'italic',
                      }}
                    >
                      {item.uspDescription}
                    </p>
                  </li>
                  ))}
                </ul>
              </div>
              {/* <img src={heroImage.url} alt={heroImage.alternativeText} title={heroImage.caption} className='hero-image img-fluid'></img> */}
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <Faq title={data.faqs.title} faqItems={data.faqs.faq_item} />
      </Container>
      <Container style={{ marginBottom: '3rem' }}>
        <div
          style={{
            textAlign: 'center',
            maxWidth: '100%',
            borderRadius: 12,
            // boxShadow: '0px 0px 16px #ececec',
            margin: '0 auto',
            marginBottom: '3rem',
            padding: '3rem',
            background: '#ececec',
          }}
        >
          <h2 style={{ fontSize: '2.1rem' }}>{data.cta.title}</h2>
          <div style={{ paddingBottom: '1rem' }}>
            <p style={{ fontSize: 16 }}>
              {data.cta.body}
            </p>
          </div>
          <CalendlyButton></CalendlyButton>
        </div>
      </Container>
    </Layout>
  );
};

export default ResellerPage;
