import Container from "react-bootstrap/esm/Container";
import Marco from "../../images/about/marco-circle.png"
import Jeroen from "../../images/about/jeroen-circle.png"
import ReactMarkdown from "react-markdown";

export function AboutPeople({ team }) {
  return (
    <div className='about__people-wrapper'>
      <Container>
        <div className='about__people-headline'>
          <ReactMarkdown>{team.data.team}</ReactMarkdown>
          <h3 style={{paddingTop:24,paddingBottom:24}}>RentVisie Board</h3>
          <div className='about__people-board' >
            <div>
              <img src={Marco} alt="Marco" />
              <h4>Marco Stein</h4>
              <a href="mailto:mstein@rentvisie.com">mstein@rentvisie.com</a>
            </div>
            <div>
            <img src={Jeroen} alt="Jeroen" />
              <h4>Jeroen Swart</h4>
              <a href="mailto:jswart@rentvisie.com">jswart@rentvisie.com</a>
            </div>
          </div>
        </div>
        {/* <div className='about__people-listing'>
          <div className='about__people-listing-title'>
            <h3>RentVisie Board</h3>
          </div>
          <div className='about__people-listing-names'>
            <div>
              <img src={Marco} alt="Marco" />
              <h4>Marco Stein</h4>
              <a href="mailto:mstein@rentvisie.nl">mstein@rentvisie.nl</a>
            </div>
            <div>
            <img src={Jeroen} alt="Jeroen" />
              <h4>Jeroen Swart</h4>
              <a href="mailto:jswart@rentvisie.nl">jswart@rentvisie.nl</a>
            </div>
          </div>
        </div> */}
        {/* <div className='about__people-listing'>
          <div className='about__people-listing-title'>
            <h3>Ambassadeurs</h3>
          </div>
          <div className='about__people-listing-names'>
            <div>
              <img src={Marco} alt="Marco" />
              <h4>Erik Timmermans</h4>
            </div>
            <div>
            <img src={Jeroen} alt="Jeroen" />
              <h4>?</h4>
            </div>
          </div>
        </div> */}
      </Container>
    </div>
  );
}

export default AboutPeople;
