import Container from "react-bootstrap/esm/Container"
import Layout from "../components/layout"
import WikiCategories from "../components/wiki/wiki-listing"


const Wiki = () => {
    return (
        <Layout>
            <div className='title-section__light'>
                <h1>RentVisie Wiki</h1>
                <p>Instructies voor de meestgebruikte modules</p>
            </div>
            <Container style={{ minHeight: '30vh'}}>
                <WikiCategories />
            </Container>
        </Layout>
    )
}

export default Wiki